<template>
  <ul :class="paginationClasses.ul">

    <li
      v-if="paginationLabels.first"
      :class="`${paginationClasses.li} ${
        hasFirst ? paginationClasses.liDisable : ''
      }`"
      class="first"
    >
      <chevrons-left-icon
        @click="first"
        :disabled="hasFirst"
        :class="`${paginationClasses.button} ${
          hasFirst ? paginationClasses.buttonDisable : ''
        }`"
        :size="chevronSize"
      >
      </chevrons-left-icon>
    </li>

    <li
      v-if="paginationLabels.prev"
      :class="`${paginationClasses.li} ${
        hasFirst ? paginationClasses.liDisable : ''
      }`"
    >
      <chevron-left-icon
        @click="prev"
        :disabled="hasFirst"
        :class="`${paginationClasses.button} ${
          hasFirst ? paginationClasses.buttonDisable : ''
        }`"
        :size="chevronSize"
      >
      </chevron-left-icon>
    </li>

    <li>
      <p class="text-base pagination-link cursor-default">
        Page {{ value }} of {{ pageCount }}
      </p>
    </li>

    <li
      v-if="paginationLabels.next"
      :class="`${paginationClasses.li} ${
        hasLast ? paginationClasses.liDisable : ''
      }`"
    >
      <chevron-right-icon
        @click="next"
        :disabled="hasLast"
        :class="`${paginationClasses.button} ${
          hasLast ? paginationClasses.buttonDisable : ''
        }`"
        :size="chevronSize"
      >
      </chevron-right-icon>
    </li>

    <li
      v-if="paginationLabels.last"
      :class="`${paginationClasses.li} ${
        hasLast ? paginationClasses.liDisable : ''
      }`"
      class="last"
    >
      <chevrons-right-icon
        @click="last"
        :disabled="hasLast"
        :class="`${paginationClasses.button} ${
          hasLast ? paginationClasses.buttonDisable : ''
        }`"
        :size="chevronSize"
      ></chevrons-right-icon>
    </li>
  </ul>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
} from "vue-feather-icons";

const defaultClasses = {
  ul: "pagination",
  li: "pagination-item",
  liActive: "pagination-item--active",
  liDisable: "pagination-item--disable",
  button: "pagination-link",
  buttonActive: "pagination-link--active",
  buttonDisable: "pagination-link--disable",
};

const defaultLabels = {
  first: "&laquo;",
  prev: "&lsaquo;",
  next: "&rsaquo;",
  last: "&raquo;",
};

export default {
  components: {
    ChevronLeftIcon,
    ChevronsLeftIcon,
    ChevronRightIcon,
    ChevronsRightIcon,
  },

  props: {
    value: {
      // current page
      type: Number,
      required: true,
    },
    pageCount: {
      // page numbers
      type: Number,
      required: true,
    },
    classes: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      chevronSize: "16",
      paginationClasses: {
        ...defaultClasses,
        ...this.classes,
      },
      paginationLabels: {
        ...defaultLabels,
        ...this.labels,
      },
    };
  },

  mounted() {
    // if (this.value > this.pageCount) {
    //   this.$emit('input', this.pageCount)
    // }
  },

  computed: {
    items() {
      let valPrev = this.value > 1 ? this.value - 1 : 1; // for easier navigation - gives one previous page
      let valNext =
        this.value < this.pageCount ? this.value + 1 : this.pageCount; // one next page
      let extraPrev = valPrev === 3 ? 2 : null;
      let extraNext =
        valNext === this.pageCount - 2 ? this.pageCount - 1 : null;
      let dotsBefore = valPrev > 3 ? 2 : null;
      let dotsAfter = valNext < this.pageCount - 2 ? this.pageCount - 1 : null;

      let output = [];

      for (let i = 1; i <= this.pageCount; i += 1) {
        if (
          [
            1,
            this.pageCount,
            this.value,
            valPrev,
            valNext,
            extraPrev,
            extraNext,
            dotsBefore,
            dotsAfter,
          ].includes(i)
        ) {
          output.push({
            label: i,
            active: this.value === i,
            disable: [dotsBefore, dotsAfter].includes(i),
          });
        }
      }

      return output;
    },

    hasFirst() {
      return this.value === 1;
    },

    hasLast() {
      return this.value === this.pageCount;
    },
  },

  watch: {
    value: function () {
      this.$emit("change");
    },
  },

  methods: {
    first() {
      if (!this.hasFirst) {
        this.$emit("input", 1);
      }
    },

    prev() {
      if (!this.hasFirst) {
        this.$emit("input", this.value - 1);
      }
    },

    goto(page) {
      this.$emit("input", page);
    },

    next() {
      if (!this.hasLast) {
        this.$emit("input", this.value + 1);
      }
    },

    last() {
      if (!this.hasLast) {
        this.$emit("input", this.pageCount);
      }
    },
  },
};
</script>

<style scoped>

.pagination {
  background: transparent !important;
}

p{
  font-family: "Poppins";
}
.pagination-item {
  width: 32px;
  height: 32px;
  display: flex;
  background: #f3f2f7;
  align-items: center;
  justify-content: center;
}

.pagination-item .pagination-link {
  border: none;
  margin: 0;
}



.pagination-item:first-child {
  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 5rem 0 0 5rem !important;
  overflow: hidden;
}

.pagination-item:last-child {
  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 0 5rem 5rem 0 !important;
  overflow: hidden;
}

.pagination-item--active .pagination-link {
  background-color: transparent !important;
  color: #757575;
}

.pagination-link {
  background: transparent;
  cursor: pointer;
}
.pagination-item {
  background: transparent;
}


.pagination-link--disable {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  color: #dddddd;
}
</style>
