<template>
  <div>
    <tabs :tabs-list="tabs" @handleEvent="log"></tabs>
    <div>
      <vs-row class="items-start">
        <vs-col
          class="pr-5"
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
          vs-w="12"
        >
          <vx-card class="custom-vx-card">
            <vs-table :data="paymentrequests" class="pay-activity-table notifi">
              <template slot="thead">
                <vs-th>
                  <div>
                    <div class="combo-box" @click="changeFilter('date')">
                      <label>Date sent</label>
                      <transition name="item">
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon
                          :class="searchParam.sortBy === 'date' && searchParam.sortDir === 'asc' ? 'dark' : ''"
                          icon="arrow_drop_up"
                        />
                        <vs-icon
                          :class="searchParam.sortBy === 'date' && searchParam.sortDir === 'desc' ? 'dark' : ''"
                          icon="arrow_drop_down"
                        />
                      </div>
                      </transition>
                    </div>

                    <transition name="item">

                    <date-picker
                      v-model="searchParam.date"
                      class="pay-date"
                      format="MM/DD/YY"
                      lang="en"
                      placeholder="MM/DD/YYYY"
                      v-if="!filtersHidden"
                      type="date"
                      v-bind:class="{ isFocus: searchParam.date }"
                      valueType="format"
                      @change="onChangeInput()"
                    ></date-picker>
                    </transition>
                  </div>
                </vs-th>
                <vs-th>
                  <div>
                    <div class="combo-box" @click="changeFilter('reference')">
                      <label>Reference</label>
                      <transition name="item">
                       <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'reference' && searchParam.sortDir === 'asc' ? 'dark' : ''
                          "
                          icon="arrow_drop_up"
                        />
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'reference' && searchParam.sortDir === 'desc' ? 'dark' : ''
                          "
                          icon="arrow_drop_down"
                        />
                      </div>
                      </transition>
                    </div>
                    <transition name="item">

                    <vs-input
                      v-if="!filtersHidden"
                      v-model="searchParam.reference"   v-bind:class="{ isFocus: searchParam.reference}" class="w-auto"   @change="onChangeInput()" />

                      </transition>
                  </div>
                </vs-th>

                <vs-th>
                  <div>
                    <div class="combo-box" @click="changeFilter('customer')">
                      <label>Customer</label>
                      <transition name="item">
                       <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'customer' && searchParam.sortDir === 'asc' ? 'dark' : ''
                          "
                          icon="arrow_drop_up"
                        />
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'customer' && searchParam.sortDir === 'desc' ? 'dark' : ''
                          "
                          icon="arrow_drop_down"
                        />
                      </div>
                      </transition>
                    </div>
                    <transition name="item">

                    <vs-input
                      v-if="!filtersHidden"
                      v-model="searchParam.customer"  v-bind:class="{ isFocus: searchParam.customer}"  class="w-auto"  @change="onChangeInput()"/>
                    </transition>

                  </div>
                </vs-th>
                <vs-th>
                  <div>
                    <div class="combo-box" @click="changeFilter('requestType')">
                      <label>Request type</label>
                      <transition name="item">
                       <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'requestType' && searchParam.sortDir === 'asc' ? 'dark' : ''
                          "
                          icon="arrow_drop_up"
                        />
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'requestType' && searchParam.sortDir === 'desc' ? 'dark' : ''
                          "
                          icon="arrow_drop_down"
                        />
                      </div>
                      </transition>
                    </div>

                    <transition name="item">
                    <vs-select
                      v-if="!filtersHidden"

                      class="w-auto" v-model="searchParam.requestType" v-bind:class="{ isFocus: searchParam.requestType}"    @change="onChangeInput()">
                      <vs-select-item
                        v-for="(item, index) in requestTypes"
                        :key="index"
                        :text="item.text"
                        :value="item.value"
                      />
                    </vs-select>
                    </transition>
                  </div>
                </vs-th>
                <vs-th>
                  <div>
                    <div class="combo-box" @click="changeFilter('amount')">
                      <label>Amount</label>
                      <transition name="item">
                       <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon
                          :class="searchParam.sortBy === 'amount' && searchParam.sortDir === 'asc' ? 'dark' : ''"
                          icon="arrow_drop_up"
                        />
                        <vs-icon
                          :class="searchParam.sortBy === 'amount' && searchParam.sortDir === 'desc' ? 'dark' : ''"
                          icon="arrow_drop_down"
                        />
                      </div>
                      </transition>
                    </div>
                    <transition name="item">
                    <vs-input
                      v-if="!filtersHidden"
                      class="w-auto"  v-model="searchParam.amount" v-bind:class="{ isFocus: searchParam.amount}"   @change="onChangeInput()"/>
                    </transition>
                  </div>
                </vs-th>

                <vs-th>
                  <div>
                    <div class="combo-box" @click="changeFilter('statusType')">
                      <label>Status</label>
                      <transition name="item">
                       <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'statusType' && searchParam.sortDir === 'asc' ? 'dark' : ''
                          "
                          icon="arrow_drop_up"
                        />
                        <vs-icon
                          :class="
                            searchParam.sortBy === 'statusType' && searchParam.sortDir === 'desc' ? 'dark' : ''
                          "
                          icon="arrow_drop_down"
                        />
                      </div>
                      </transition>
                    </div>
                    <transition name="item">
                    <vs-select
                      v-if="!filtersHidden"
                      v-model="searchParam.statusType" class="w-auto"  :multiple="true"    v-bind:class="{ isFocus: searchParam.statusType}" @change="onChangeInput()" @click="changeStatus($event)">
                      <vs-select-item
                        v-for="(item, index) in statusTypes"
                        :key="index"
                        :text="item.text"
                        :value="item.value"
                      />
                    </vs-select>
                    </transition>
                  </div>
                </vs-th>
                <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                  <filter-icon size="1.5x" class="custom-class"
                               :fill="filterIconColor.fill"
                               :stroke="filterIconColor.stroke"
                               @click="filtersHidden=!filtersHidden"></filter-icon>
                </vs-th>
              </template>


              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td :data="tr.createdAt">
                    {{ formatDate(tr.createdAt) }}
                  </vs-td>
                  <vs-td :data="tr.reference">
                    {{ tr.reference }}
                  </vs-td>

                  <vs-td :data="tr.customer">
                    {{ (tr.customer.length>0)? tr.customer.firstName + " " + tr.customer.lastName : "" }}
                  </vs-td>

                  <vs-td v-if="searchParam.type === 'PR'" :data="tr.requestType">
                    {{ tr.requestType }}
                  </vs-td>

                  <vs-td v-else :data="tr.transactionId">
                    {{ tr.transactionId }}
                  </vs-td>

                  <vs-td :data="tr.amount">
                    {{ formatCurrency(tr.amount) }}
                  </vs-td>

                  <vs-td :data="tr.paymentStatus">
                    {{ tr.status }}
                  </vs-td>

                  <vs-td :data="tr.paymentStatus">
                    <span class="underline cursor-pointer">view</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div>
              <vs-row  class="px-5 my-10 flex justify-between items-center">
                <div>
                  <vs-select
                    v-model="searchParam.limit"
                    class="records-show records-per-page flex items-center"
                    label="Show records"
                  >
                    <vs-select-item
                      v-for="(item, index) in limitValue"
                      :key="index"
                      :text="item.text"
                      :value="item.value"
                    />
                  </vs-select>
                </div>

                <div vs-w="6">
                  <vPagination
                    v-model="searchParam.pageNumber"
                    :page-count="totalPage"
                    :total="totalPage"
                  ></vPagination>
                </div>
              </vs-row>
            </div>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import Tabs from "@/views/components/Tabs";
import TableFilter from "@/components/custom-filter/TableFilter";
import moment from "moment";
import { FilterIcon } from 'vue-feather-icons'
import DatePicker from "vue2-datepicker";
import vPagination from "@/views/components/pagination";

export default {
  name: "NotificationList",
  data() {
    return {
       filtersHidden: true,
      searchParam: {
        pageNumber: 1,
        totalData: 0,
        limit: 50,
        type: "PR",
        date: "",
        reference: "",
        customer: "",
        requestType: "all",
        amount: "",
        statusType: ["all"],
        pageList: ["all"],
      },
      requestTypes: [
        { text: "All", value: "all" },
        { text: "One off", value: "one-off" },
        { text: "Recurring", value: "recurring" }
      ],
      statusTypes: [
        { text: "Show all", value: "all" },
        { text: "Pending", value: "Pending" },
        { text: "Active", value: "Active" },
        { text: "Completed", value: "Completed" },
        { text: "Expired", value: "Expired" },
        { text: "Cancelled", value: "Cancelled" }
      ],
      paymentrequests: [],
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      tabs: [
        { key: "PR", text: "Payment Request" },
        { key: "TX", text: "Transaction" },
      ],
    };
  },
  components: {
    Tabs,
    TableFilter,
    DatePicker,
    vPagination,
    FilterIcon
  },
  methods: {
    log(e) {
      this.searchParam.type = e;
    },
    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.searchParam.limit;
        })
        .catch((err) => {

        });
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {})
        .catch((err) => {

        });
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/yyyy");
    },
    goToPaymentActivity(val){

    },
    async getPaymentRequestList() {
      this.$vs.loading();
      const partnerId = JSON.parse(localStorage.getItem("user"))._id;
      const data = {
        partnerId: partnerId,
        searchParam: this.searchParam,
      };
      await this.$store
        .dispatch("history/fetchHistoryListWithFilter", data)
        .then((result) => {
          this.paymentrequests = result.data.data.docs;
          this.searchParam.totalData = result.data.data.pagination.total
            ? result.data.data.pagination.total
            : 0;

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();

        });
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },
  mounted() {
    this.getListSetting();
    this.getPaymentRequestList();
  },
  watch: {
    "searchParam.pageNumber"(page) {
      this.getPaymentRequestList();
    },
    "searchParam.limit"(val) {
      this.getPaymentRequestList();
      this.saveListSetting(val);
    },
    "searchParam.date"(val) {

      this.getPaymentRequestList();
    },
    "searchParam.reference"(val) {

      this.getPaymentRequestList();
    },

    "searchParam.customer"(val) {

      this.getPaymentRequestList();
    },

    "searchParam.type"(val) {

      this.getPaymentRequestList();
    },

    "searchParam.requestType"(val) {

      this.getPaymentRequestList();
    },

    "searchParam.amount"(val) {

      this.getPaymentRequestList();
    },
    "searchParam.statusType"(val) {

      this.getPaymentRequestList();
    },
    "searchParam.pageList"(val) {
      this.getPaymentRequestList();
    },
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    filterIconColor(){
      if (!this.filtersHidden){
        return { fill:"#0f67f4",stroke:"#0f67f4"}
      }

      return { fill:"white",stroke:"black"}
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
    totalPage: function () {
      return Math.ceil(this.searchParam.totalData / this.searchParam.limit);
    },
  },
};
</script>
