<template>
  <div class="grid grid-cols-2 w-full h-full tab">
    <div
      class="tab__item"
      v-for="(tab, index) in tabsList"
      :key="index"
      @click="handleClick(tab.key)"
      :class="{ selected: isSelected === tab.key }"
    >
      <span class="lg:text-2xl md:text-xl sm:text-lg text-lg mb-0" style="color: inherit">{{ tab.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabsList: {
      type: Array,
      required: true,
    },
    defaultSelect: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSelected: this.defaultSelect,
    };
  },
  methods: {
    handleClick(value) {
      this.isSelected = value;
      this.$emit("handleEvent", value);
    },
  },
};
</script>

<style lang="css">
.tab__item:last-child {
  border: 3px solid #bdbdbd;
  border-left: none;
  border-radius: 0px 8px 8px 0px;
}

.tab__item:first-child {
  border: 3px solid #bdbdbd;
  border-right: none;
  border-radius: 8px 0px 0px 8px;
}
.tab__item {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @apply py-2 px-2 w-full flex;
}

.selected {
  border: 3px solid #0f67f4 !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  color: #0f67f4 !important;
}
#rellopay .selected {
  border-color: #006cac !important;
  color: #006cac !important;
}

.tab {
  width: 99%;
  margin: 0 1rem 0.8rem 0;
}
</style>
