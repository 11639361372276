<template>
  <div class="w-full">
    <vs-row class="justify-between mb-10 items-center">
      <div>
        <vs-select class="selectExample" v-model="searcParam.pageList">
          <vs-select-item
            :key="index"
            :value="item._id"
            :text="item.title"
            v-for="(item, index) in pagesList"
          />
        </vs-select>
      </div>
      <div>
        <vs-button
          v-round
          color="primary"
          @click="exportToCsv()"
          icon="get_app"
          type="filled"
          >Export<span v-if="loadingText">ing</span></vs-button
        >
      </div>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  props: ["searcParam"],
  data() {
    return {
      loadingText: false,

      pagesList: [],

      statusArrayAll: ["pending", "active", "complete", "expired", "cancelled"],
    };
  },

  methods: {
    ...mapActions("page", ["fetchPagesByPartnerId"]),
    ...mapActions("paymentRequest", [
      "exportPaymentRequestListByPartnerIdWithFilter",
    ]),

    async getPagesBypartnerId() {
      const partnerId = JSON.parse(localStorage.getItem("user"))._id;
      await this.fetchPagesByPartnerId(partnerId)
        .then((response) => {
          this.pagesList = response.data.data;
          this.pagesList.push({
            title: "All templates & hosted pages",
            _id: "all",
          });
       
        })
        .catch((err) => {
          
        });
    },

    changeFilter(value) {
      this.sort.by = value;
      this.sort.dir = this.sort.dir === "desc" ? "asc" : "desc";
    },

    changeStatus(event) {
      let showArray = this.statusType;
      //if(showArray.includes('all')){
      //this.statusType = ['all'];
      //}
      
    },
    async exportToCsv() {
      this.loadingText = true;
      const partnerId = JSON.parse(localStorage.getItem("user"))._id;
      const data = {
        partnerId: partnerId,
        searcParam: this.searcParam,
      };

      await this.exportPaymentRequestListByPartnerIdWithFilter(data)
        .then((response) => {
          
       

          this.downloadCsv(response.data.data);
          this.loadingText = false;
        })
        .catch((err) => {
          
          this.loadingText = false;
        });
      
    },
    downloadCsv(val) {
      const path = process.env.VUE_APP_API_URL;
      const filePath = "uploads/exports/";
      window.location.href = path + filePath + val;
      this.loadingText = false;
    },
  },
  mounted() {
    this.getPagesBypartnerId();
  },
};
</script>
<style lang="scss">
.filter-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.combo-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.combo-box label {
  width: 100% !important;
  font-weight: 500;
  font-size: 16px;
}
.icon-combo {
  display: flex;
  flex-direction: column;
}

.dark {
  color: black;
}
.active {
  border: 1px solid #0f67f4;
  border-radius: 1%;
}
#rellopay .active {
  border-color: #006cac;
}
</style>
